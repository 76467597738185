import React, { useState, useImperativeHandle, forwardRef } from 'react'

import useDelay from './use-delay'

import { Input } from 'antd'

const SearchFieldWithKeyUpListener = forwardRef(({ callback, delay, placeholder, autoFocus }, ref) => {
  const [search, setSearch] = useState('')

  useImperativeHandle(ref, () => ({
    emptySearch() {
      setSearch('')
    },
  }))

  useDelay(() => callback(search), delay || 500, [search])

  return <Input className="sb-input sb-shadow" value={search} placeholder={placeholder || 'Хайх'} onChange={(e) => setSearch(e.target.value)} />
})
export default SearchFieldWithKeyUpListener
